import { Form, Input, Button, Select, Row, Col, Avatar } from "antd";
import { UserOutlined } from '@ant-design/icons';
import { NavigationContext } from "../contexts/NavigationContext";
import React, { useContext, useState } from "react";

const { Option } = Select;

const Translations = () => {
  const [selectedLanguage1, setSelectedLanguage1] = useState(null);
  const [selectedLanguage2, setSelectedLanguage2] = useState(null);

  const { handleNavigate } = useContext(NavigationContext);
  const onFinish = (values: any) => {
    handleNavigate("/translations/new-conversation", { formValues: values });
  };

  const languages = [
    { label: "Anglès", value: "Anglès" },
    { label: "Català", value: "Català" },
    { label: "Urdu", value: "Urdu" },
    { label: "Àrab", value: "Àrab" },
    { label: "Francès", value: "Francès" },
  ];

  const handleLanguage1Change = (value) => {
    setSelectedLanguage1(value);
  };

  const handleLanguage2Change = (value) => {
    setSelectedLanguage2(value);
  };


  return (
    <div className="container">
      <h2>Crear traducció</h2>
      <p>
        Es obligatori completar tots els camps per poder iniciar la traducció.
      </p>
      <Form
        labelCol={{ span: 8 }}
        wrapperCol={{ span: 24 }}
        layout="vertical"
        onFinish={onFinish}
      >
        <Row gutter={[32, 32]}>
          <Col span={20}>
            <Form.Item
              label="Títol"
              name="translationName"
              rules={[
                {
                  required: true,
                  message: "Camp obligatori",
                },
              ]}
            >
              <Input placeholder="Títol de la traducció" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Avatar
              size={48}
              icon={<UserOutlined />}
              style={{ backgroundColor: "#e6e6e6", marginBottom: 10 }}
            />
            <Form.Item
              label="Nom"
              name="participant1"
              rules={[
                {
                  required: true,
                  message: "Camp obligatori",
                },
              ]}
            >
              <Input required placeholder="Nom" />
            </Form.Item>
          </Col>
          <Col span={10}>
            <Avatar size={48} icon={<UserOutlined />} style={{ backgroundColor: "#e6e6e6", marginBottom: 10 }} />
            <Form.Item
              label="Nom"
              name="participant2"
              rules={[
                {
                  required: true,
                  message: "Camp obligatori",
                },
              ]}
            >
              <Input placeholder="Nom" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={[32, 32]}>
          <Col span={10}>
            <Form.Item
              label="Idioma"
              name="language1"
              rules={[
                {
                  required: true,
                  message: "Camp obligatori",
                },
              ]}
            >
              <Select placeholder="Seleccionar idioma" onChange={handleLanguage1Change} value={selectedLanguage1}>
                {languages.filter(lang => lang.value !== selectedLanguage2).map(lang => (
                  <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
          <Col span={10} >
            <Form.Item
              label="Idioma"
              name="language2"
              rules={[
                {
                  required: true,
                  message: "Camp obligatori",
                },
              ]}
            >
              <Select placeholder="Seleccionar idioma" onChange={handleLanguage2Change} value={selectedLanguage2}>
                {languages.filter(lang => lang.value !== selectedLanguage1).map(lang => (
                  <Option key={lang.value} value={lang.value}>{lang.label}</Option>
                ))}
              </Select>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={16}>
            <Form.Item wrapperCol={{ offset: 0, span: 16 }}>
              <Button
                htmlType="submit"
                style={{
                  width: 120,
                  height: 36,
                  
                }}
              >
                Crear
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};

export default Translations;
